import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import blogPhoto from "../../content/assets/blogphoto.jpg"
import { rhythm } from "../utils/typography"
import Navbar from "./navbar"

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
            <img src={blogPhoto} alt="blog-header"/>
      )
    } else {
      header = (
        <h3
          style={{
            marginTop: 0,
            fontFamily: `Lato, sans-serif`,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `#082567`,
            }}
            to={`/`}
          >
             ← Powrót 
          </Link>
         
        </h3>
      )
    }
    return (
      <Wrapper>
        <Navbar/>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(32),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
        </div>
        <Footer>
          © {new Date().getFullYear()}, Kancelaria Radcy Prawnego -
          {` `}
          <a style={{boxShadow: 'none'}} href="https://www.facebook.com/jurczynskikancelaria/">Paweł Jurczyński</a>
        </Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
