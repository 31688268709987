import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "./../../content/assets/white_logo.svg"
import styled from "styled-components"

const NAVBAR = styled.nav`
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: #031746;
  height: 170px;
  @media (max-width: 768px) {
    justify-content: space-evenly;
  }
`

const NAV_CONTAINER = styled.div`
  display: flex;

  a {
    box-shadow: none;
    color: none;
    text-decoration: none;
  }
`

const LOGO = styled.div`
  margin-left: 45px;
  display: flex;
  width: 250px;
  height: 150px;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const TITLE = styled.div`
  font-weight: bolder;

  left: 47%;
  position: absolute;
  @media (max-width: 768px) {
    position: initial
  }
  a {
    box-shadow: none;
    color: white;
    text-decoration: none;
  }

  h1 {
    margin: 0;
    font-size: 3.5em;
    font-weight: 300;
  }
  @media (max-width: 768px) {
    h1 {
    font-size: 1.75em;
    }
  }
`

export default class Navbar extends Component {
  render() {
    const { data } = this.props
    console.log(data)
    return (
      <NAVBAR>
        <NAV_CONTAINER>
          <LOGO>
            <a href="https://www.jurczynskikancelaria.pl/">
              <img
                src={logo}
                className="favicon"
                alt="logo-jurczynski-kancelaria"
              />
            </a>
          </LOGO>
        </NAV_CONTAINER>
        <TITLE>
          <Link to="/">
            <h1>BLOG</h1>
            </Link>
        </TITLE>
      </NAVBAR>
    )
  }
}
