import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    body: {
      fontFamily: ["Lato, sans-serif"].join(","),
    },
    h1: {
      fontFamily: ["Lato, sans-serif"].join(","),
    },
    h3: {
      fontFamily: ["Lato, sans-serif"].join(","),
    },
    p: {
      textAlign: `justify`,
      lineHeight: `24px`,
    },

    ul: {
      fontFamily: ["Lato, sans-serif"].join(","),
      textAlign: `justify`,
      lineHeight: `24px`,
      listStylePosition: `inside !important`,
    },
    ol: {
      listStylePosition: `inside`,
      textAlign: `justify`,
    },
    html: {
      font: `100%/1.75 'Lato',sans-serif`,
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
